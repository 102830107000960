import React from "react";
import { Provider } from "react-redux";
import * as checkoutAPI from "tsi-common-react/src/api/checkout";
import { registerCheckoutCascades } from "tsi-common-react/src/apps/checkout/cascades-checkout";
import { registerCommonCascades } from "tsi-common-react/src/apps/checkout/cascades-main";
import { Dispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/checkout/loaders";
import * as signals from "tsi-common-react/src/apps/signals";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { persistor, rehydratingStore, store } from "../store";

// ============================================================================
// Setup Redux Store / Redux Event Dispatchers
// ============================================================================
const dispatchers = new Dispatchers(store.dispatch);
const loaders = new Loaders(dispatchers);

// ============================================================================
// Render checkout app components
// ============================================================================
/* eslint-disable-next-line no-async-promise-executor */
const componentsRendering = rehydratingStore.then(() => {
    // Register store cascades
    registerCommonCascades(store);

    // Build some common JSX for the basket sidebar
    const basketSidebarExtraContent = (
        <div className="basket-summary__help">
            <h3>Questions?</h3>
            <p className="basket-summary__text">
                Call{" "}
                <a
                    className="basket-summary__phone-link"
                    href={getPageSetting("pre-order-phone-number-link")}
                >
                    {getPageSetting("pre-order-phone-number-display")}
                </a>
                .
            </p>
        </div>
    );

    // Render Basket Application
    dynamicPlaceComponent("#basket-app", async () => {
        const { Basket } = await import(
            "tsi-common-react/src/apps/checkout/containers/Basket"
        );
        return (
            <Provider store={store}>
                <Basket
                    getLineProductUpsell={() => {
                        return null;
                    }}
                    showValueProps={true}
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={() => {
                        return basketSidebarExtraContent;
                    }}
                    getExtraMainColumnContent={() => {
                        return null;
                    }}
                />
            </Provider>
        );
    }).catch(console.error);

    // Render Checkout Application
    dynamicPlaceComponent("#checkout-app", async () => {
        registerCheckoutCascades(store);
        const { Checkout } = await import(
            "tsi-common-react/src/apps/checkout/containers/Checkout"
        );
        return (
            <Provider store={store}>
                <Checkout
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={() => {
                        return basketSidebarExtraContent;
                    }}
                    buildFinancingUpsell={() => {
                        return null;
                    }}
                />
            </Provider>
        );
    }).catch(console.error);

    // Render Message Banner
    dynamicPlaceComponent(
        '[data-place-react="message-banner"]',
        async (elem) => {
            const { MessageBanner } = await import(
                "tsi-common-react/src/apps/checkout/containers/MessageBanner"
            );
            const tags = elem.dataset.tags || "";
            const message = elem.dataset.message || "";
            return (
                <Provider store={store}>
                    <MessageBanner tags={tags} message={message} />
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Create Account Widgets
    dynamicPlaceComponent(
        '[data-place-react="create-account"]',
        async (elem) => {
            const { CreateAccount } = await import(
                "tsi-common-react/src/apps/checkout/containers/CreateAccount"
            );
            const firstName = elem.dataset.firstName || "";
            const email = elem.dataset.email || "";
            const currentUserID = elem.dataset.currentUserId;
            const orderUserID = elem.dataset.orderUserId;
            const orderUserHasPassword =
                (elem.dataset.orderUserHasPassword || "").toLowerCase() ===
                "true";
            const orderHistoryUrl = isoWebPageURL.wrap(
                elem.dataset.orderHistoryUrl || "",
            );
            const isAuthenticatedAsOrderOwner = currentUserID === orderUserID;
            return (
                <CreateAccount
                    firstName={firstName}
                    email={email}
                    isAuthenticatedAsOrderOwner={isAuthenticatedAsOrderOwner}
                    orderOwnerHasPassword={orderUserHasPassword}
                    orderHistoryURL={orderHistoryUrl}
                />
            );
        },
    ).catch(console.error);

    // Render Offer images
    dynamicPlaceComponent('[data-place-react="order-offer"]', async (elem) => {
        const desktop_image = elem.dataset.desktop_image || "";
        const mobile_image = elem.dataset.mobile_image || "";
        const offer_freq = elem.dataset.offer_freq || "";
        const benefit_value = elem.dataset.benefit_value || "";
        const offer_name = elem.dataset.offer_name || "";

        const { OrderOfferImage } = await import(
            "./components/OrderOfferImage"
        );
        const { OrderOffer } = await import("./components/OrderOffer");
        if (!!offer_freq && !!benefit_value) {
            return (
                <Provider store={store}>
                    <OrderOffer
                        offer_freq={offer_freq}
                        benefit_value={benefit_value}
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                        offer_name={offer_name}
                    />
                </Provider>
            );
        }
        if (!!desktop_image && !!mobile_image) {
            return (
                <Provider store={store}>
                    <OrderOfferImage
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                    />
                </Provider>
            );
        }
        return null;
    }).catch(console.error);
});

// ============================================================================
// Special On-Page-Load Functionality
// ============================================================================
const wipeCheckoutData = () => {
    console.debug("Erasing transient checkout data...");
    persistor.purge();
    checkoutAPI.clearShippingAddress();
    checkoutAPI.clearBillingAddress();
    loaders.resetFormData();
};

onReadyStateComplete.on(async () => {
    // Wait for redux store to finish loading
    await rehydratingStore;
    await componentsRendering;

    // Redirect from basket page to homepage after 60 min delay
    const basketApp = document.getElementById("basket-app");
    if (basketApp) {
        setTimeout(() => {
            urls.navigateToHome();
        }, 3600_000);
    }

    // Wipe checkout data?
    if (document.body.classList.contains("js-wipe-checkout-data")) {
        wipeCheckoutData();
    }
});

// ============================================================================
// CSR Toolbar Integration
// ============================================================================
const reloadCheckoutData = () => {
    wipeCheckoutData();
    console.log("Reloading basket data");
    loaders.loadBasket();
    console.log("Reloading shipping and billing address");
    loaders.loadShippingAndBillingAddresses();
};

signals.csr.onBeforeStartNewBasket.on(wipeCheckoutData);
signals.csr.onAfterStartAssistingCustomer.on(reloadCheckoutData);
signals.csr.onAfterStopAssistingCustomer.on(reloadCheckoutData);
