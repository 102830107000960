import React from "react";
import { Provider } from "react-redux";
import { IImageURL, isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import { rehydratingStore, store } from "../store";
import * as pdpModels from "./models";

/**
 * Load CMS data and the initially selected product variant
 */
let cmsData: pdpModels.IProductPage | null = null;
let pdpHeroImage: IImageURL | null = null;
let pdpMobileHeroImage: IImageURL | null = null;
let pdpHeroAlt: string | null = null;
let pdpVariantHeroImages: pdpModels.IProductPage["variant_hero_images_with_urls"] =
    [];

const detailContainerElem = document.querySelector<HTMLDivElement>(
    "#product-detail-container[data-cms]",
);
let pdpRootProductDescriptions: pdpModels.IProductPage["variant_configurator_description_data"] =
    [];

if (detailContainerElem) {
    // Load PDP CMS data
    cmsData = JSON.parse(detailContainerElem.dataset.cms || "");
    if (cmsData) {
        pdpHeroImage = cmsData.hero_image_url;
        pdpMobileHeroImage = cmsData.mobile_hero_image_url;
        pdpHeroAlt = cmsData.hero_image_alt;
        pdpVariantHeroImages = cmsData.variant_hero_images_with_urls;
        pdpRootProductDescriptions =
            cmsData.variant_configurator_description_data;
    }
}

/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="modular-configurator"]',
    async (elem) => {
        const { ModularConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
        );

        const { ChatLink } = await import(
            "tsi-common-react/src/apps/chat/ChatLink"
        );

        const isAlternativeDesign = elem.dataset.design === "alt";
        const phoneNumber = elem.dataset.phoneNumber;
        // Wait for the document to load, this needs to render multi product options properly
        await readyStateComplete;
        await rehydratingStore;
        return (
            <Provider store={store}>
                <ModularConfigurator
                    optionSetJSON={elem.dataset.configuratorInitData || ""}
                    passthroughProps={{
                        title: cmsData ? cmsData.title : "",
                        preheader: cmsData ? cmsData.preheader : "",
                        topBadgeContent: cmsData ? cmsData.top_badge : "",
                        subhead: cmsData ? cmsData.subhead : "",
                        description:
                            cmsData && cmsData.description
                                ? cmsData.description
                                : "",
                        descriptions: pdpRootProductDescriptions
                            ? pdpRootProductDescriptions
                            : [],
                        promoCopy: cmsData ? cmsData.promo_copy : "",
                        enableHistoryInteraction: true,
                        buttonColor: "primary",
                        configType: "pdp-hero",
                        getDeliveryCopy: () => {
                            return cmsData && cmsData.shipping_description
                                ? cmsData.shipping_description
                                : "";
                        },
                        getDeliveryIcon: () => {
                            return cmsData && cmsData.shipping_icon_url
                                ? cmsData.shipping_icon_url
                                : null;
                        },
                        showShippingLead: !!(
                            cmsData && cmsData.show_shipping_lead
                        ),
                        liveChatHeader: cmsData ? cmsData.live_chat_header : "",
                        chatLinkComponent: !isAlternativeDesign ? null : (
                            <ChatLink
                                className={"button"}
                                chatOnlineText={t`Chat`}
                                chatOfflineLink={urls.pageURL("contact-link")}
                            />
                        ),
                        showStarRatingsModal: true,
                        showFeelScale: true,
                        showPreTitle: false,
                        showVariantCallout: false,
                        starRatingURL: isoWebPageURL.wrap("#reviews"),
                        learnMoreSelector: cmsData
                            ? `${cmsData.learn_more_target}`
                            : "#pdp-change-variant-block",
                        strikeThroughMSRP: false,
                        actualPriceStyle: "",
                        boxHeaderContent: "",
                        phoneNumber: phoneNumber,
                        showImagePerUpgradeOption: false,
                        showFinancingModal: !!(
                            cmsData && cmsData.show_financing_modal
                        ),
                    }}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render Galleries
 */
dynamicPlaceComponent('[data-place-react="pdp-gallery"]', async () => {
    const { Gallery } = await import("./containers/PDPGallery");
    await readyStateComplete; // Wait for the document to completely load, then render the Gallery Component.
    return (
        <Provider store={store}>
            <Gallery />
        </Provider>
    );
}).catch(console.error);

/**
 * Render EmailCaptureBlock
 */
dynamicPlaceComponent(
    '[data-place-react="email-capture-block"]',
    async (elem) => {
        const { EmailCaptureContent } = await import(
            "tsi-common-react/src/apps/common/containers/EmailCaptureContent"
        );
        const cms_data = JSON.parse(elem.dataset.cms || "null");

        return <EmailCaptureContent content={cms_data} />;
    },
).catch(console.error);

/**
 * Place PDPHero components
 */
dynamicPlaceComponent('[data-place-react="pdp-hero"]', async () => {
    const { PDPHero } = await import(
        "tsi-common-react/src/apps/configurator/containers/PDPHero"
    );
    return (
        <Provider store={store}>
            <PDPHero
                defaultBackgroundImage={pdpHeroImage}
                defaultMobileBackgroundImage={pdpMobileHeroImage}
                defaultImageTitle={pdpHeroAlt || ""}
                variantHeroInfo={pdpVariantHeroImages}
            />
        </Provider>
    );
}).catch(console.error);
